@tailwind base;
@tailwind components;
@tailwind utilities;

html {scroll-behavior: smooth;}
body {
  margin: 0;
  font-family: 'meno-text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #152548;
  line-height: 1.2;
}
h1 {line-height: 1.1;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-height: 965px) {
  .h-main-content {
    height: auto !important;
  }  
}

@media screen and (max-height: 1145px) {
  .h-main-content.lg\:py-0 {padding: 2rem 0px;}
}
