.col-row {
    @apply w-full break-after-column text-left text-navy;
}
.col-header {
    @apply font-bold w-full border-b-2 border-black-700 mb-6 pb-2 text-base md:text-lg;
}
.main-content {
    @apply flex justify-center flex-col py-16;
}

